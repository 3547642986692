.hero {
  display: flex;
  background-image: url("/images/hero_home_phone.jpg");
  background-position: 50% 80%;
  background-size: cover;
  background-repeat: no-repeat;
  height: 535px !important;
}

.pcherobg {
  display: none;
}

.mobherobg {
  display: block;
}

.cta {
  font-size: 23pt;
  color: white;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.1;
  padding-top: 16rem;
}

.sub_cta {
  font-size: 12pt;
  font-weight: 500;
  color: white;
  line-height: 1.5;
  margin-bottom: 0;
}

.section_header {
  font-size: 18pt;
  font-weight: 600;
}

.app {
  display: block;
}

@media (min-width: 1024px) {
  .hero {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.7) 0%,
        rgba(255, 255, 255, 0) 25%,
        rgba(255, 255, 255, 0)
      ),
      url("/images/hero_pc.jpg");
    background-position: 70% 60%;
    background-size: cover;
    height: 100vh !important;
    width: 100% !important;
    max-width: none !important;
  }

  .pcherobg {
    display: block;
  }

  .mobherobg {
    display: none;
  }

  .cta {
    font-weight: 600;
    font-size: 44pt;
    line-height: 65px;
    padding-top: 0rem;
  }

  .sub_cta {
    padding-top: 10px;
    font-size: 18px;
    font-weight: 500;
  }

  .section_header {
    font-size: 1.5rem;
  }
}

@media (min-width: 1280px) {
  .hero {
    height: 85vh !important;
  }
}
